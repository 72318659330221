const counties = () => {
  return [
    { name: 'Blekinge', value: 'Blekinge län' },
    { name: 'Dalarna', value: 'Dalarnas län' },
    { name: 'Gotland', value: 'Gotlands län' },
    { name: 'Gävleborg', value: 'Gävleborgs län' },
    { name: 'Halland', value: 'Hallands län' },
    { name: 'Jämtland', value: 'Jämtlands län' },
    { name: 'Jönköping', value: 'Jönköpings län' },
    { name: 'Kalmar', value: 'Kalmar län' },
    { name: 'Kronoberg', value: 'Kronobergs län' },
    { name: 'Norrbotten', value: 'Norrbottens län' },
    { name: 'Skåne', value: 'Skåne län' },
    { name: 'Stockholm', value: 'Stockholms län' },
    { name: 'Södermanland', value: 'Södermanlands län' },
    { name: 'Uppsala', value: 'Uppsala län' },
    { name: 'Värmland', value: 'Värmlands län' },
    { name: 'Västerbotten', value: 'Västerbottens län' },
    { name: 'Västernorrland', value: 'Västernorrlands län' },
    { name: 'Västmanland', value: 'Västmanlands län' },
    { name: 'Västra Götaland', value: 'Västra Götalands län' },
    { name: 'Örebro', value: 'Örebro län' },
    { name: 'Östergötland', value: 'Östergötlands län' }
  ]
}

export default counties
