<template>
  <div class="box has-max-width">
    <ContentPlaceholders v-if="isLoading" :rounded="true">
      <ContentPlaceholdersHeading />
      <ContentPlaceholdersText :lines="4" />
    </ContentPlaceholders>

    <div v-else>
      <FormField :label="title">
        <div v-html="helpText" />
      </FormField>

      <Button
        type="danger"
        :icon="buttonIcon"
        :title="buttonTitle"
        :loading="isSubmitting"
        @click="confirm">
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STATE_DEFAULT = ''
const STATE_SUBMITTING = 'submitting'

export default {
  data () {
    return {
      state: STATE_DEFAULT
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      isAdmin: 'huntarea/isAdmin'
    }),

    isLoading () {
      return this.huntArea === null
    },

    isSubmitting () {
      return this.state === STATE_SUBMITTING
    },

    title () {
      const key = this.isAdmin ? 'settings.delete.title' : 'settings.leave.title'
      return this.$i18n.t(key)
    },

    helpText () {
      const key = this.isAdmin ? 'settings.delete.helpText' : 'settings.leave.helpText'
      return this.$i18n.t(key)
    },

    buttonIcon () {
      return this.isAdmin ? 'icon-trash' : ''
    },

    buttonTitle () {
      const key = this.isAdmin ? 'general.remove' : 'settings.leave.title'
      return this.$i18n.t(key)
    }
  },

  methods: {
    async confirm () {
      try {
        const params = this.isAdmin ? undefined : { type: 'leave' }
        const response = await this.$removeDialog.confirm(params)

        if (response.ok) {
          await this.submit()
        }
      } catch (error) {
        const errorMessage = this.isAdmin ? 'settings.delete.title' : 'settings.leave.title'
        this.$notification.danger(this.$i18n.t(errorMessage))
        console.error(error)
      }
    },

    async submit () {
      this.state = STATE_SUBMITTING

      try {
        const action = this.isAdmin ? 'delete' : 'leave'
        await this.$store.dispatch(`huntarea/${action}`, this.huntArea)
        this.$router.push('/')
      } catch (error) {
        this.state = STATE_DEFAULT
        throw error
      }
    }
  }
}
</script>
