<template>
  <div class="box has-max-width">
    <form @submit.prevent="save">
      <FormField :label="$t('settings.name')">
        <TextInputField
          id="huntAreaName"
          v-model="area.name"
          :disabled="!canEdit">
        </TextInputField>

        <p class="help">{{ $t('settings.nameHelpText') }}</p>
      </FormField>

      <FormField v-if="showCountyPicker" :label="$t('settings.county.title')">
        <div class="select select-county">
          <select id="county" v-model="area.county" :disabled="!canEdit">
            <option value="">{{ $t('settings.county.notSpecified') }}</option>
            <option v-for="county in counties" :key="county.value" :value="county.value">
              {{ county.name }}
            </option>
          </select>
        </div>

        <Paragraph :text="$t('settings.county.helpText')" class="help" />
      </FormField>

      <Button
        v-if="isAdmin"
        type="primary"
        :loading="isSaving"
        :title="$t('general.save')"
        @click="save">
      </Button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import counties from '@/api/static/counties'
import HuntAreaModel from '@/api/huntarea/huntAreaModel.js'

const STATE_DEFAULT = ''
const STATE_SAVING = 'saving'

export default {
  data () {
    return {
      state: STATE_DEFAULT,
      counties: counties(),
      area: new HuntAreaModel({
        name: '',
        county: ''
      })
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      isAdmin: 'huntarea/isAdmin'
    }),

    isSaving () {
      return this.state === STATE_SAVING
    },

    canEdit () {
      return this.huntArea !== null && this.isSaving === false && this.isAdmin
    },

    showCountyPicker () {
      const countryCode = this.$store.getters['ui/getCountryCode']
      return countryCode === 'se' // For now only show the county picker for Swedish users
    }
  },

  watch: {
    huntArea (huntArea) {
      this.area = new HuntAreaModel(huntArea) // Make copy so we don't modify value in vuex
    }
  },

  mounted () {
    if (this.huntArea) {
      this.area = new HuntAreaModel(this.huntArea) // Make copy so we don't modify value in vuex
    }
  },

  methods: {
    async save () {
      this.state = STATE_SAVING

      try {
        await this.$store.dispatch('huntarea/update', this.area)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.saveFailedErrorMessage'))
        console.error(error)
      } finally {
        this.state = STATE_DEFAULT
      }
    }
  }
}
</script>
