<template>
  <div class="box has-max-width">
    <h5 class="title is-6 push-bottom-xs">{{ $t('settings.viltrapport.title') }}</h5>

    <InlineLoader v-if="isLoading" />

    <div v-else>
      <p v-if="districts.length > 0">
        <span v-html="$t('settings.viltrapport.helpText')"></span>
      </p>

      <article v-else class="message is-warning is-marginless">
        <div class="message-body">
          <strong>{{ $t('settings.viltrapport.notConnectedTitle') }}</strong><br>
          {{ $t('settings.viltrapport.notConnectedMessage') }}
        </div>
      </article>

      <table class="table is-fullwidth">
        <tbody>
          <tr v-for="district in districts" :key="district.id">
            <td>
              <Icon name="icon-database" /> {{ district.name }}
            </td>
            <!--
            <td class="has-text-right">
              <Icon name="icon-trash-2 is-clickable has-text-danger" />
            </td>
            -->
          </tr>
        </tbody>
      </table>

      <!--
      <button type="button" class="button is-primary">Anslut till skötselområde</button>
      -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      districts: 'huntarea/viltrapport/getDistricts'
    }),

    isLoading () {
      return this.districts === null
    }
  }
}
</script>

<style scoped>
.table tbody tr td {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}
</style>
