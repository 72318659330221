<template>
  <div>
    <Titlebar :title="$t('settings.title')" />

    <div class="default-spacing is-paddingless-top">
      <SettingsHuntAreaCard />
      <SettingsViltrapportIntegrationCard />
      <SettingsLeaveOrRemoveCard />
    </div>
  </div>
</template>

<script>
import Titlebar from '@/modules/core/components/layout/Titlebar.vue'

import SettingsHuntAreaCard from './SettingsHuntAreaCard.vue'
import SettingsViltrapportIntegrationCard from './SettingsViltrapportIntegrationCard.vue'
import SettingsLeaveOrRemoveCard from './SettingsLeaveOrRemoveCard.vue'

export default {
  components: {
    Titlebar,
    SettingsHuntAreaCard,
    SettingsViltrapportIntegrationCard,
    SettingsLeaveOrRemoveCard
  }
}
</script>
